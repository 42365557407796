<template>
  <div class="parameter-setting">
    <a-form :model="form" layout="horizontal" ref="formRef">
      <!-- 用印前是否必须拍照 -->
      <a-form-item label="用印前是否必须拍照" name="photo" :colon="false">
        <a-switch
          :checked="form.beforeSealPhoto === 1"
          class="switch"
          @change="value => setSealChange(value, 'before')"
        ></a-switch>
      </a-form-item>
      <!-- 用印后自动抓拍 -->
      <!-- <a-form-item label="用印后自动抓拍" v-if="isBind" :colon="false">
        <a-switch
          :checked="form.afterSealSnap === 1"
          class="switch snap-right"
          @change="value => setSealChange(value, 'after')"
        ></a-switch>
        <template v-if="form.afterSealSnap === 1">
          <span>自动抓拍时间间隔： {{ form.snapInterval ?? '1.5' }}s</span>
          <div style="margin-right:8px;display:inline-block"></div>
          <a-tooltip placement="top" class="tips-text">
            <template #title>
              <span style="display:block;text-align:center;font-size: 14px;">时间间隔可在企业管理-参数设置中配置</span>
            </template>
            <QuestionCircleFilled class="charts-icon" />
          </a-tooltip>
        </template>
      </a-form-item> -->
      <!-- 是否允许开启用印后自动抓拍： -->
      <a-form-item v-if="isBind" label="是否允许开启用印后自动抓拍：" name="" class="form-item" :colon="false">
        <a-switch :checked="form.afterSealSnap === 1" @click="alterConfirm(form.afterSealSnap, 4)" class="switch" />
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >开启时，管理员可在流程名称管理中开启自动抓拍，开启后，该流程用印完成后将会在抓拍间隔后自动抓拍一张用印影像</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
        </a-tooltip>
        <span style="margin-left:40px;">抓拍间隔：</span>
        <a-input-number
          :disabled="form.afterSealSnap === 2"
          v-model:value="form.snapInterval"
          :step="0.5"
          :min="0.5"
          :max="60"
          :parser="
            value =>
              value
                .replace(/[^\d.]/g, '')
                .replace(/\.{2,}/g, '.')
                .replace('.', '$#$')
                .replace('$#$', '.')
                .replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3')
                .replace(/^\./g, '')
                .replace(/(\.(?!0|5).)?$/g, '')
          "
        />
        <span>&nbsp;&nbsp;s</span>
      </a-form-item>
      <!-- 是否强制补拍 -->
      <a-form-item label="是否强制补拍" name="" class="form-item" :colon="false">
        <a-switch :checked="form.makeUp === 1" @click="alterConfirm(form.makeUp, 1)" class="switch" />
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >开启后，盖印人一旦盖印必须补拍，否则无法使用其他待用印</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
        </a-tooltip>
      </a-form-item>
      <!-- 盖印现需要盖印人验证指纹 -->
      <a-form-item label="盖印需要盖印人验证指纹" name="" class="form-item" :colon="false">
        <a-switch :checked="form.authentication === 1" @click="alterConfirm(form.authentication, 2)" />
      </a-form-item>
      <!-- 用印时间限制 -->
      <a-form-item label="用印时间限制" name="stampingTime" :autoLink="false" class="form-item" :colon="false">
        <a-switch :checked="form.usageTime === 1" @change="changeUsageTime" />
        <div>
          <a-checkbox v-model:checked="isSetDays" :disabled="form.usageTime === 2"></a-checkbox>
          <span style="margin-left:5px">用印人在获得审批后</span>
          <a-select
            :disabled="!isSetDays"
            v-model:value="form.printTime"
            :style="{ width: '71px', margin: '8px' }"
            :options="options"
          ></a-select>
          <span>天内完成用印，超时无法使用</span>
        </div>
        <div>
          <a-checkbox v-model:checked="isSetHours" :disabled="form.usageTime === 2"></a-checkbox>
          <span style="margin-left:5px">用印人在发生盖印后</span>
          <a-input-number
            :disabled="!isSetHours"
            id="inputNumber"
            v-model:value="form.stampingTime"
            :precision="0"
            :min="1"
            :max="24"
            :style="{ width: '71px', margin: '8px' }"
          />
          <span>小时内完成用印，超时无法使用</span>
        </div>
      </a-form-item>
      <!-- 用印地点限制 -->
      <a-form-item label="用印地点限制" name="" class="form-item" :colon="false">
        <a-switch :checked="form.locationLimit === 1" @click="alterConfirm(form.locationLimit, 3)" />
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >开启时，未在电子围栏范围内，设备被锁定，不可用印！</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
        </a-tooltip>
      </a-form-item>
      <!-- 是否开启指纹快捷用印 -->
      <a-form-item label="是否开启指纹快捷用印" name="" class="form-item" :colon="false">
        <a-switch :checked="form.fastSeal === 1" @change="changeQuickPrintStatus" />
        <div style="margin-right:8px;display:inline-block"></div>
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >开启时，非特权用印用户直接在设备验证指纹后，可使用第一条待用印文件</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" />
        </a-tooltip>
      </a-form-item>
      <!-- 天玺拍摄视频 -->
      <a-form-item label="天玺拍摄视频" name="" class="form-item" :colon="false" v-if="form.processType != 2">
        <a-switch :checked="form.equipmentRec === 1" @change="changeShootingVideos" />
        <div style="margin-right:8px;display:inline-block"></div>
        <a-tooltip placement="top" class="tips-text">
          <template #title>
            <span style="display:block;text-align:center;font-size: 14px;"
              >开启时，需在表单设计的盖印方式中配置范围内用印功能，天玺才会录制视频</span
            >
          </template>
          <QuestionCircleFilled class="charts-icon" />
        </a-tooltip>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref, createVNode, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined, QuestionCircleFilled } from '@ant-design/icons-vue'
import { getConfigure } from '@/apis/businessManage'

export default defineComponent({
  components: {
    QuestionCircleFilled
  },
  props: {
    isBind: {
      //是否绑定高拍仪
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useStore()
    const loading = ref(false)

    const form = computed({
      get() {
        return store.state.process.basic
      },
      set(val) {
        store.state.process.basic = val
      }
    })
    const isSetDays = computed({
      get() {
        return store.state.process.isSetDays
      },
      set(val) {
        store.state.process.isSetDays = val
      }
    })
    const isSetHours = computed({
      get() {
        return store.state.process.isSetHours
      },
      set(val) {
        store.state.process.isSetHours = val
      }
    })
    // 用印前切换开关
    const setSealChange = (value, type) => {
      console.log(value, type)
      if (type === 'before') {
        if (value) return (form.value.beforeSealPhoto = 1)
        // OCR 对比开着的时候弹窗
        // if (form.value.ocrType == 1) {
        //   // 关闭拍照时会关闭ocr
        //   return Modal.confirm({
        //     title: '是否关闭',
        //     width: '400px',
        //     content: '请确认是否关闭，如果关闭ocr功能无法正常使用',
        //     centered: true,
        //     confirmLoading: loading.value,
        //     icon: createVNode(ExclamationOutlined),
        //     onOk: () => {
        //       form.value.ocrType = 2 //关闭ocr识别 ocr状态(1. 启用 2. 关闭)
        //       form.value.beforeSealPhoto = 2
        //     },
        //     onCancel: () => {}
        //   })
        // }
        // OCR识别没开着 直接关闭
        form.value.beforeSealPhoto = 2
      } else {
        form.value.afterSealSnap = value ? 1 : 2
      }
    }
    // 关闭时提示框
    const alterConfirm = (val, type) => {
      console.log('点击开关的值', form.value.makeUp, val)
      let content = ''
      let title = ''
      function Play() {}
      var p = new Play()
      switch (type) {
        case 1:
          //是否强制补拍
          console.log('这里的值', form.value.makeUp)
          title = '确认关闭'
          content = '关闭后该流程新增文件均无需补拍，是否确认关闭？'
          p.open = function() {
            form.value.makeUp = 1
          }
          p.close = function() {
            form.value.makeUp = 2
          }
          break
        case 2:
          //盖印需要盖印人验证指纹
          title = '确认关闭'
          content = '关闭后将增加企业用印风险，是否确认关闭'
          p.open = function() {
            form.value.authentication = 1
          }
          p.close = function() {
            form.value.authentication = 2
          }
          break
        case 3:
          //用印地点限制
          title = '是否关闭！'
          content = '关闭后可在电子围栏外用印，是否确认关闭？'
          p.open = function() {
            form.value.locationLimit = 1
          }
          p.close = function() {
            form.value.locationLimit = 2
          }
          break
        case 4:
          //是否允许开启用印后自动抓拍
          title = '是否关闭！'
          content = '开启时，该流程用印完成后将会在抓拍间隔后自动抓拍一张用印影像'
          p.open = function() {
            form.value.afterSealSnap = 1
          }
          p.close = function() {
            form.value.afterSealSnap = 2
          }
          break
      }
      if (val === 1) {
        Modal.confirm({
          title,
          icon: createVNode(ExclamationOutlined),
          content,
          okText: '确认',
          cancelText: '取消',
          centered: true,
          onCancel() {
            p.open()
          },
          onOk() {
            p.close()
          }
        })
      } else {
        p.open()
      }
    }
    //用印时间限制
    const changeUsageTime = e => {
      console.log('用印时间开关', e, typeof e)

      form.value.usageTime = e ? 1 : 2
      if (!e) {
        //关闭用印限制，禁止勾选
        isSetDays.value = false
        isSetHours.value = false
      }
    }
    //是否开启指纹快捷用印
    const changeQuickPrintStatus = e => {
      console.log('是否开启指纹快捷用印', e)
      form.value.fastSeal = e ? 1 : 2
    }

    const changeShootingVideos = e => {
      form.value.equipmentRec = e ? 1 : 2
    }
    onMounted(() => {
      console.log('表单的值', isSetDays.value, isSetHours.value)
    })
    return {
      form,
      loading,
      setSealChange,
      alterConfirm,
      isSetDays,
      isSetHours,
      options: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 10 },
        { value: 15 }
      ],
      changeUsageTime,
      changeQuickPrintStatus,
      changeShootingVideos
    }
  }
})
</script>
<style lang="less" scoped>
.parameter-setting {
  background: #fff;
  margin: 60px auto 0;
  width: 670px;
  padding: 32px 70px;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 775px;
  .snap-right {
    margin-right: 16px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
</style>
